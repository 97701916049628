import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
  max-width: 600px;
  padding: 30px;

  span {
    line-height: 22px;
  }

  img {
    width: 25px;
    margin: 0 0 5px 5px;
  }

  mark {
    color: #fff;
    background-color: #161616;
  }

  a {
    color: #617be3;

    &:hover {
      opacity: 0.7;
    }
  }
`;

import styled from 'styled-components';

export const GridProgress = styled.div`
  display: grid;
  grid-gap: auto;
  grid-template-columns: repeat(4, minmax(160px, auto));

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, minmax(160px, auto));
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 3em;
      font-weight: 300;
    }

    p {
      text-transform: uppercase;
      font-size: 2em;
      font-weight: 200;
    }
  }
`;

import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import market from './market/sagas';
import inventory from './inventory/sagas';
import cart from './cart/sagas';

export default function* rootSaga() {
  return yield all([auth, user, market, inventory, cart]);
}

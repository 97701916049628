import styled from 'styled-components';

function getColor(quality) {
  switch (quality) {
    case 'normal': {
      return '#999';
    }

    case 'magic': {
      return '#96bb7c';
    }

    case 'rare': {
      return '#617be3';
    }

    case 'epic': {
      return '#916dd5';
    }

    case 'legendary': {
      return '#F79F4A';
    }
    default:
      return '#123';
  }
}

export const Container = styled.div`
  color: #000;
  position: relative;
  left: 0;
  right: 0;
  margin: 2.5em auto 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;

  @media (max-width: 1100px) {
    grid-gap: 1.5em;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  > div {
    display: flex;
    height: 180px;
    width: 300px;
    margin-bottom: 1em;
    padding: 0 1em;
  }

  img {
    min-width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  h1 {
    display: inline;
    vertical-align: top;
    font-weight: 300;
    font-size: 1.4em;
  }

  p {
    display: block;
    text-align: start;
    font-weight: 300;
    font-size: 1.2em;
  }
`;

export const ItemQuality = styled.span`
  color: ${props => getColor(props.quality)};
  font-weight: 500;
`;

import React from 'react';
import queryString from 'query-string';

import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signInRequest, signFailure } from '~/store/modules/auth/actions';

// import { Container } from './styles';

export default function Login() {
  const dispatch = useDispatch();
  const value = queryString.parse(window.location.search);
  if (!value.code) {
    dispatch(signFailure());
    return <Redirect to="/" />;
  }
  dispatch(signInRequest(value.code));
  return <Redirect to="/" />;
}

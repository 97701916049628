import React from 'react';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import basic from '~/assets/donate-cards/basic.svg';
import premium from '~/assets/donate-cards/premium.svg';
import checkmark from '~/assets/donate-cards/checkmark.svg';
import background from '~/assets/donate-cards/background.svg';

import { Container, Card, Top, CardContainer } from './styles';
import Footer from '~/components/Footer';

import { AddToCart } from '~/store/modules/cart/actions';
import { store } from '~/store';

import history from '~/services/history';

export default function Donate() {
  const dispatch = useDispatch();
  const { signed } = store.getState().auth;

  const basicVip = {
    name: 'Basic Vip',
    images: ['https://ilumnia.s3.amazonaws.com/website/basic.svg'],
    amount: 200,
    currency: 'usd',
    quantity: 1,
  };

  const premiumVip = {
    name: 'Premium Vip',
    images: ['https://ilumnia.s3.amazonaws.com/website/premium.svg'],
    amount: 500,
    currency: 'usd',
    quantity: 1,
  };

  const ilumnium = {
    name: 'Ilumnium',
    images: ['https://ilumnia.s3.amazonaws.com/website/premium.svg'],
    amount: 500,
    quantity: 1,
  };

  function handleAddCart(vip) {
    if (!signed) {
      toast.error('You must login to add to cart');
      return;
    }
    dispatch(AddToCart(vip));
    history.push('/cart');
  }
  return (
    <>
      <Container background={background}>
        <Top background={background}>
          <h1>Pricing</h1>
          <h2>Choose any of the following plans to get start with</h2>
          <h2>Each plan has 30 days</h2>
        </Top>
        <CardContainer background={background}>
          <Card vip="basic">
            <img className="avatar" src={basic} alt="Basic Vip" />
            <div className="container">
              <div className="line" />
              <h2>Basic</h2>
              <h1>2</h1>
              <hr />
              <ul>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>+25% Combat EXP</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>+25% Silver Drop</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Vip Daily</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Vip Vote Reward</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Vip Commands</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Rest 50min cooldown</p>
                </li>
              </ul>
              <button
                type="button"
                onClick={() => {
                  handleAddCart(basicVip);
                }}
              >
                ADD TO CART
              </button>
            </div>
          </Card>
          <Card vip="premium">
            <img className="avatar" src={premium} alt="Premium Vip" />
            <div className="container">
              <div className="line" />
              <h2>Premium</h2>
              <h1>5</h1>
              <hr />
              <ul>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>+50% Combat EXP</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>+50% Silver Drop</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Premium Daily</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Vip Vote Reward</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Vip Commands</p>
                </li>
                <li>
                  <img src={checkmark} alt="Checkmark" />
                  <p>Rest 40min cooldown</p>
                </li>
              </ul>
              <button
                type="button"
                onClick={() => {
                  handleAddCart(premiumVip);
                }}
              >
                ADD TO CART
              </button>
            </div>
          </Card>
        </CardContainer>
      </Container>
      <Footer />
    </>
  );
}

export function marketRequest(typeMarket, itemId) {
  return {
    type: '@market/MARKET_REQUEST',
    payload: { typeMarket, itemId },
  };
}

export function marketRequestName(typeMarket, itemName, price) {
  return {
    type: '@market/MARKET_REQUEST_NAME',
    payload: { typeMarket, itemName, price },
  };
}

export function MarketSuccess(market) {
  return {
    type: '@market/MARKET_SUCCESS',
    payload: { market },
  };
}

export function MarketListingsRequest() {
  return {
    type: '@market/MARKET_LISTINGS_REQUEST',
  };
}

export function MarketListingsSuccess(listings) {
  return {
    type: '@market/MARKET_LISTINGS_SUCCESS',
    payload: { listings },
  };
}

export function MarketListingsFailure() {
  return {
    type: '@market/MARKET_LISTINGS_FAILURE',
  };
}

export function MarketRemoveItem(market_id, listings) {
  return {
    type: '@market/MARKET_REMOVE_ITEM',
    payload: { market_id, listings },
  };
}

export function orderMarket(market) {
  return {
    type: '@market/MARKET_ORDER',
    payload: { market },
  };
}

export function MarketBuyRequest(
  user,
  market_id,
  quantity,
  index,
  market,
  itemType
) {
  return {
    type: '@market/MARKET_BUY_REQUEST',
    payload: { user, market_id, quantity, market, index, itemType },
  };
}

export function MarketBuySuccess(user, market) {
  return {
    type: '@market/MARKET_BUY_SUCCESS',
    payload: { user, market },
  };
}

export function MarketBuyFailure() {
  return {
    type: '@market/MARKET_BUY_FAILURE',
  };
}

export function MarketRegisterRequest(
  typeMarket,
  silver_price,
  user_selling,
  silver_tax,
  quantity,
  item,
  inventory
) {
  return {
    type: '@market/MARKET_REGISTER_REQUEST',
    payload: {
      typeMarket,
      silver_price,
      user_selling,
      silver_tax,
      quantity,
      item,
      inventory,
    },
  };
}

export function MarketRegisterSuccess(user, typeMarket, newInventory) {
  return {
    type: '@market/MARKET_REGISTER_SUCCESS',
    payload: { user, typeMarket, newInventory },
  };
}

export function MarketRegisterFailure() {
  return {
    type: '@market/MARKET_REGISTER_FAILURE',
  };
}

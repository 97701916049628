import styled from 'styled-components';

export const Container = styled.div`
  background-color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  height: 86.5vh;
`;

export const FilterContainer = styled.div`
  display: flex;
  height: 80vh;
  width: 260px;
  background-color: #1a1a1a;
  flex-direction: column;
`;

export const ItemsContainer = styled.div`
  display: flex;
  height: 80vh;
  width: 260px;
  margin-right: 10px;
  background-color: #1a1a1a;
  flex-direction: column;
  padding: 2em 0;
  color: rgba(255, 255, 255, 0.8);

  h2 {
    padding-left: 1.4em;
    margin-bottom: 5px;
    font-weight: 400;
  }
  > p {
    font-size: 16px;
    padding-left: 2em;
  }

  hr {
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin-top: 1em;
  }

  ul {
    padding-left: 2em;
    button {
      color: #fff;
      font-size: 1.2em;
      background: 0;
      outline: none;
      border: 0;
    }
    li {
      margin: 2em 0;
    }
  }
`;

export const MarketContainer = styled.div`
  display: flex;
  background-color: #1a1a1a;
  height: 80vh;
  width: 800px;
  margin-right: 10px;
  flex-direction: column;
  padding: 1.2em 0 1em 0;

  hr {
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin: 1.2em 0 1em 0;
  }

  .market-container {
    display: flex;
    > p {
      margin-left: auto;
    }

    button {
      background: 0;
      outline: none;
      border: 0;
      color: #fff;
      :first-child {
        padding-right: 1em;
        margin: 0 1em;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #2c2f33;
  height: 98.5vh;
  font-family: MontSerrat, sans-serif;
  color: #fff;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100px;
  h1 {
    font-size: 32px;
    margin: 1em;
  }
`;

export const Products = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  margin: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;

  div {
    display: flex;
    flex-direction: column;
  }

  img {
    height: 100px;
    border-radius: 50px;
    margin-right: 20px;
  }
`;

export function AddToCart(item) {
  return {
    type: '@cart/ADD_ITEM',
    payload: { item },
  };
}

export function RemoveFromCart(item) {
  return {
    type: '@cart/REMOVE_ITEM',
    payload: { item },
  };
}

export function DeleteFromCart(item) {
  return {
    type: '@cart/DELETE_ITEM',
    payload: { item },
  };
}

export function PaymentRequest(cart, user_id, username) {
  return {
    type: '@cart/PAYMENT_REQUEST',
    payload: { cart, user_id, username },
  };
}

export function PaymentSuccess() {
  return {
    type: '@cart/PAYMENT_SUCCESS',
  };
}

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  font-family: MontSerrat, sans-serif;
  flex-direction: column;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 130vh;

  @media (max-width: 770px) {
    height: 1500px;
  }
`;

export const Top = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 3em;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
  }

  h2 {
    color: #ddd;
    font-weight: 300;
    text-align: center;
    padding: 5px 20px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 770px) {
    flex-direction: column;
    margin: 0;
  }
`;
export const Card = styled.div`
  min-width: 320px;
  max-width: 350px;
  min-height: 650px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:first-child {
    margin-right: 100px;
  }

  @media (max-width: 770px) {
    &:first-child {
      margin-right: 0;
    }
  }

  .avatar {
    align-self: center;
    overflow: hidden;
    margin-bottom: -70px;
  }

  .container {
    display: flex;
    z-index: 8;
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    top: 12vh;
    flex-direction: column;
    align-items: center;
    font-family: MontSerrat, sans-serif;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.9);

    hr {
      width: 80%;
      height: 1px;
      border: 0;
      margin: 10px 0;
      background-image: ${props =>
        props.vip === 'basic'
          ? 'linear-gradient(109.6deg,rgba(79, 148, 243, 0.73) 11.2%,rgba(140, 105, 193, 0.87) 91.2%)'
          : 'radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,193,104,1) 0%, rgba(251,128,128,1) 90% )'};
    }

    button {
      width: 70%;
      padding: 1em 0;
      margin-bottom: 30px;
      border: 0;
      background-color: #fbb04d;
      transition: 0.2s;
      font-size: 1.4em;
      font-weight: bold;

      &:hover {
        background-color: ${darken(0.1, '#fbb04d')};
      }
    }

    ul {
      margin: 20px 0 30px 30px;
      align-self: flex-start;
      li {
        display: flex;
        margin-bottom: 20px;
        p {
          font-size: 1.2em;
        }
        img {
          margin-right: 20px;
        }
      }
    }

    .line {
      width: 100%;
      height: 20px;
      background-image: ${props =>
        props.vip === 'basic'
          ? 'linear-gradient(109.6deg,rgba(79, 148, 243, 0.73) 11.2%,rgba(140, 105, 193, 0.87) 91.2%)'
          : 'radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,193,104,1) 0%, rgba(251,128,128,1) 90% )'};
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 400;
      margin: 30px 0;
    }

    h1 {
      display: inline;
      font-size: 4em;

      &:before {
        content: '$';
        font-size: 0.6em;
        vertical-align: top;
        margin-right: 0.3em;
      }

      &:after {
        content: '/mo';
        font-size: 0.5em;
        font-weight: 400;
        margin-left: 0.3em;
      }
    }
  }
`;

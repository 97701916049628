import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import { inventorySuccess, inventoryFailure } from './actions';

export function* InventoryRequest() {
  const response = yield call(api.get, 'inventory/user');

  const { equipment, consumable, material, pet } = response.data;

  if (!equipment) {
    yield put(inventoryFailure());
  }

  yield put(inventorySuccess(equipment, consumable, material, pet));
}

export default all([
  takeLatest('@inventory/INVENTORY_REQUEST', InventoryRequest),
]);

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #131313;
  padding: 2em;
  display: flex;
  justify-content: space-between;
  font-family: MontSerrat, sans-serif;
  align-items: center;

  nav {
    display: none;
  }

  @media (min-width: 1050px) {
    nav {
      align-self: flex-end;
      display: flex;
    }

    ul {
      display: flex;
      align-items: center;
    }

    li {
      padding: 0 1em;
      color: #fff;
      text-transform: uppercase;
      position: relative;
    }

    button {
      background: none;
      font-weight: 400;
      font-size: 1.2em;
      color: #fff;
      border: 0;
    }

    p {
      font-size: 1.2em;
      color: #fff;
    }

    a {
      font-size: 1.2em;
      color: #fff;
      text-decoration: none;
      &:hover {
        font-weight: 600;
        &:after {
          content: '';
          position: absolute;
          bottom: -10px;
          right: 0;
          left: 0;
          margin: 0 auto;
          height: 1px;
          width: 50%;
          background-color: #5061ca;
        }
      }
    }
  }

  h1 {
    color: #fff;
    font-weight: 300;
    font-size: 36px;
    letter-spacing: 1px;
    line-height: 20px;
  }
`;

import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

function getBorderColor(id) {
  switch (id) {
    case 1: {
      return '#fff';
    }

    case 2: {
      return '#617be3';
    }

    case 3: {
      return '#ffd369';
    }

    case 4: {
      return '#916dd5';
    }

    case 5: {
      return '#F79F4A';
    }
    default:
      return '#fff';
  }
}

export const MarketTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead th {
    color: #ddd;
    font-weight: 400;
    text-align: left;
  }
`;

export const TableItem = styled.tr`
  transition: 0.2s all;

  &:hover {
    cursor: pointer;
    background-color: #222;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  td {
    vertical-align: middle;
    text-align: left;
    padding: 10px;
    font-size: 1.1em;
    color: #ddd;
    margin: 0;
  }

  .item_name {
    color: ${props => getBorderColor(props.quality)};
  }

  img {
    border: 1px solid ${props => getBorderColor(props.quality)};
    padding: 5px;
    width: 40px;
    border-radius: 5px;
  }

  button {
    border: 0;
    outline: none;
    color: #ddd;
    background: none;
    font-size: 1.1em;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  margin: 0 2px;
`;

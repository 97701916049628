import React from 'react';

import { Container, TopTextContainer } from './styles';

import dragon from '~/assets/home/DragonSVG.svg';

function Top() {
  return (
    <Container background={dragon}>
      <TopTextContainer>
        <h1 data-aos="fade-up" data-aos-once="true">
          A real one mmorpg in discord
        </h1>
        <p data-aos="fade-up" data-aos-once="true">
          Rpg bot based on real MMORPG games, with tankers dps, healers, party,
          bosses, craft, and so on.
        </p>
        <div data-aos="fade-up" data-aos-once="true">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="official-server"
            href="https://discordapp.com/invite/8UXcdPw"
          >
            Official Server
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="invite-ilumnia"
            href="https://discordapp.com/oauth2/authorize?client_id=644244839780712499&scope=bot&permissions=1073752128"
          >
            Invite Ilumnia
          </a>
        </div>
      </TopTextContainer>
    </Container>
  );
}

export default Top;

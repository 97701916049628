import React from 'react';

import { GridProgress } from './styles';

function Progress() {
  return (
    <>
      <h3 data-aos="fade-up" data-aos-once="true">
        Our aim is to be a <mark>real</mark> MMORPG game in Discord
      </h3>
      <p className="add-border" data-aos="fade-up" data-aos-once="true">
        We currently have:
      </p>
      <GridProgress
        className="progress-container"
        data-aos="fade-up"
        data-aos-once="true"
      >
        <div>
          <h2>2</h2>
          <p>Races</p>
        </div>
        <div>
          <h2>20</h2>
          <p>Classes</p>
        </div>
        <div>
          <h2>140</h2>
          <p>Monsters</p>
        </div>
        <div>
          <h2>26</h2>
          <p>Maps</p>
        </div>
      </GridProgress>
      <h2 data-aos="fade-up" data-aos-once="true">
        The march to being the largest begins with you.
      </h2>
      <h2 data-aos="fade-up" data-aos-once="true">
        Be part of our bold goal.
      </h2>
    </>
  );
}

export default Progress;

import React from 'react';
import { Container, Rights } from './styles';
import pdf from '~/terms/ilumnia.pdf';

export default function Footer() {
  return (
    <>
      <Container>
        <div>
          <h2>ILUMNIA</h2>
          <a target="_blank" rel="noopener noreferrer" href={pdf}>
            Terms of Service
          </a>
        </div>
        <div>
          <h1>Contact us in Discord:</h1>
          <p>Asth#2467</p>
          <p>Kidmortal#9999</p>
        </div>
      </Container>
      <Rights>
        <p>
          COPYRIGHT © 2020-2020 - All images and content on ILUMNIA belong to
          their respective creators.
        </p>
      </Rights>
    </>
  );
}

import { combineReducers } from 'redux';

import auth from './auth/reducer';
import market from './market/reducer';
import user from './user/reducer';
import inventory from './inventory/reducer';
import cart from './cart/reducer';
import menu from './menu/reducer';

export default combineReducers({
  auth,
  user,
  market,
  inventory,
  cart,
  menu,
});

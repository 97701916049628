import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';

import {
  MarketSuccess,
  MarketBuySuccess,
  MarketBuyFailure,
  MarketRegisterSuccess,
  MarketListingsSuccess,
  MarketListingsFailure,
} from './actions';
import { signOut } from '../auth/actions';

export function* marketRequest({ payload }) {
  try {
    const { typeMarket, itemId } = payload;

    const response = yield call(api.post, 'market/show', {
      typeMarket,
      itemId,
    });

    yield put(MarketSuccess(response.data));
  } catch (err) {
    history.push('/');
    yield put(signOut());
    toast.error('User not found, sign up in Ilumnia');
  }
}

export function* marketBuyRequest({ payload }) {
  try {
    const {
      user,
      market_id,
      quantity,
      market: marketItens,
      index: itemIndex,
      itemType,
    } = payload;
    const { silver, id: user_id } = user;

    const response = yield call(api.post, 'market/buy', {
      silver,
      user_id,
      market_id,
      quantity,
    });

    const newMarket = marketItens.map((item, index) => {
      if (index === itemIndex) {
        const newItem = {
          ...item,
          quantity: item.quantity - quantity,
        };
        return newItem;
      }
      return item;
    });

    const { userBuying } = response.data;

    yield put(MarketBuySuccess(userBuying, newMarket));
    toast.success(
      `You bought ${quantity} ${marketItens[itemIndex][itemType].name}`
    );
  } catch (err) {
    console.tron.log(err);
    toast.error(`You don't have enough money or item out of stock`);
    yield put(MarketBuyFailure());
  }
}

export function* marketRequestName({ payload }) {
  try {
    const { typeMarket, itemName, price } = payload;

    const response = yield call(api.post, 'market/showitem', {
      typeMarket,
      itemName,
      price,
    });

    yield put(MarketSuccess(response.data));
  } catch (err) {
    console.tron.log(err);
  }
}

function* marketRegisterRequest({ payload }) {
  try {
    const {
      typeMarket,
      silver_price,
      user_selling,
      silver_tax,
      quantity,
      item,
      inventory,
    } = payload;
    const registered = new Date();

    const { id: item_id, name } = item;

    const response = yield call(api.post, 'market/store', {
      typeMarket,
      item_id,
      silver_price,
      user_selling,
      silver_tax,
      registered,
      quantity,
    });
    console.tron.log(response);
    const newInventory = inventory[typeMarket].map(item => {
      if (item[typeMarket].id === item_id) {
        const newItem = {
          ...item,
          quantity: item.quantity - quantity,
        };
        return newItem;
      }
      return item;
    });

    yield put(MarketRegisterSuccess(response.data, typeMarket, newInventory));
    toast.success(
      `You registered ${name} for ${silver_price
        .toString()
        .replace(
          /(\d)(?=(\d{3})+(?!\d))/g,
          '$1.'
        )} silver each, you paid ${silver_tax
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}(10% fee)`
    );
  } catch (err) {
    console.tron.log(err);
  }
}

export function* marketListingRequest() {
  try {
    const response = yield call(api.get, 'market/listings');
    yield delay(20);
    yield put(MarketListingsSuccess(response.data));
  } catch (err) {
    console.tron.log(err);
    yield put(MarketListingsFailure());
  }
}

export function* marketRemoveItem({ payload }) {
  try {
    const { market_id, listings } = payload;
    yield call(api.post, 'market/remove', {
      market_id,
    });
    console.tron.log(listings);
    yield put(MarketListingsSuccess(listings));
    toast.success(`You removed your item from market`);
  } catch (err) {
    console.tron.log(err);
  }
}

export default all([
  takeLatest('@market/MARKET_REQUEST', marketRequest),
  takeLatest('@market/MARKET_BUY_REQUEST', marketBuyRequest),
  takeLatest('@market/MARKET_REQUEST_NAME', marketRequestName),
  takeLatest('@market/MARKET_REGISTER_REQUEST', marketRegisterRequest),
  takeLatest('@market/MARKET_LISTINGS_REQUEST', marketListingRequest),
  takeLatest('@market/MARKET_REMOVE_ITEM', marketRemoveItem),
]);

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  font-family: MontSerrat, sans-serif;
  overflow: hidden;
`;

export const Section = styled.div`
  margin-top: 1.15vh;
  min-width: 200px;
  max-width: 200px;
  max-height: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 5vw;
  align-items: center;

  .paragraph {
    flex: 1;
    text-transform: uppercase;
    font-weight: 500;
    text-align: start;
    white-space: nowrap;
    letter-spacing: 2px;
    line-height: 15px;
    margin-right: 15px;
    color: ${props => (props.theme === 'white' ? '#000' : '#fff')};
  }

  .line {
    width: 100%;
    border: solid 0.5px ${props => (props.theme === 'white' ? '#000' : '#fff')};
  }
`;

export const SectionContainer = styled.div`
  z-index: 1;
  position: relative;
  min-width: 50%;
  max-width: 50%;
  margin: 0 auto;
  right: 9vw;
  display: flex;
  flex-direction: column;
  color: ${props => (props.theme === 'white' ? '#000' : '#fff')};

  h1 {
    text-align: center;
    font-size: 2.4em;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 50px;
  }

  p {
    text-align: justify;
    font-size: 1.2em;
    margin-bottom: 15px;
    line-height: 23px;

    &:last-child {
      margin: 0;
    }
  }

  h3 {
    font-size: 1.4em;
    font-weight: 400;
    margin-bottom: 1em;

    mark {
      color: #7bbbe6;
      background: none;
    }
  }

  .add-border {
    border-left: 1px solid #7bbbe6;
    padding-left: 8px;
  }

  @media (max-width: 880px) {
    margin-top: 50px;
    max-width: 80%;
    right: 0;
  }
`;

export const WhySection = styled.div`
  display: flex;
  position: relative;
  padding: 10vh 0;
  background-color: #fff;
  overflow: hidden;

  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const ProgressSection = styled.div`
  display: flex;
  position: relative;
  padding: 10vh 0 15vh 0;
  background-color: #161616;
  overflow: hidden;

  .tiny-triangle {
    position: absolute;
    left: 50vw;
    top: 40vh;
  }

  .big-triangle {
    position: absolute;
    right: 55vw;
    top: 15vh;
  }

  .square {
    position: absolute;
    left: 40vw;
    bottom: 25vh;
  }

  h2 {
    margin-top: 2em;
    font-size: 1.4em;
    font-weight: 400;

    &:last-child {
      margin-top: 0;
    }
  }

  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const FeatureSection = styled.div`
  display: flex;
  position: relative;
  padding: 10vh 0;
  background-color: #fff;
  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const FaqSection = styled.div`
  display: flex;
  position: relative;
  padding: 10vh 0;
  background-color: #161616;

  @media (max-width: 880px) {
    flex-direction: column;
  }

  .footer-container {
    display: flex;
    position: relative;
    margin-top: 20vh;
  }
`;

export const GridFaq = styled.div``;

export const Footer = styled.div`
  position: relative;
  height: 50vh;
  width: 100%;
  background-color: #000;

  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 10vh;

  div {
    margin-top: 25vh;
    h1 {
      color: #fff;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  img {
    margin-top: 25vh;
  }

  @media (max-width: 1400px) {
    height: 850px;
    align-items: flex-end;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    height: 40vh;
    align-items: center;

    img {
      margin: 0;
    }

    div {
      margin: 0;
    }
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  height: 360px;
  width: 600px;

  :first-child {
    margin-right: 70px;
  }

  @media (max-width: 1300px) {
    :first-child {
      margin-right: 0;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 700px) {
    width: 500px;
    height: 300px;
  }

  @media (max-width: 500px) {
    width: 400px;
  }

  background-color: ${props =>
    props.theme === 'white' ? '#DCDCDC' : '#222331'};

  h1 {
    font-size: 1.7em;
    font-weight: 400;
    color: ${props => (props.theme === 'white' ? '#000' : '#fff')};
  }

  h2 {
    font-size: 1.6em;
    font-weight: 600;
    color: ${props => (props.theme === 'white' ? '#000' : '#fff')};
  }

  p {
    font-size: 1.2em;
    font-weight: 500;
    margin-top: 20px;
    max-width: 80%;
    color: ${props => (props.theme === 'white' ? '#000' : '#fff')};
    opacity: 0.5;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 50px;
    padding: 0 10px;
    font-size: 1.2em;
    margin-top: 20px;
    color: ${props => (props.theme === 'white' ? '#000' : '#fff')};
    background-color: ${props =>
      props.theme === 'white' ? '#9a9a9a' : '#303b5a'};
    border-color: ${props => (props.theme === 'white' ? '#9a9a9a' : '#303b5a')};
    font-weight: 400;
    font-family: MontSerrat, sans-serif;
    border-style: solid;
    transition: 0.2 all;

    &:hover {
      opacity: 0.7;
    }

    img {
      height: 35px;
      width: 35px;
      margin-right: 7px;
    }
  }
`;

export const Wrapper = styled.div`
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vh;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOut } from '~/store/modules/auth/actions';
import { menuClick } from '~/store/modules/menu/actions';

function Options() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.profile);

  function handleClick(location) {
    dispatch(menuClick(location));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <nav>
      <ul>
        <li onClick={() => handleClick('Vote')}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://top.gg/bot/644244839780712499/vote"
          >
            VOTE
          </a>
        </li>
        <li onClick={() => handleClick('Donate')}>
          <Link to="/donate">DONATE</Link>
        </li>
        <li onClick={() => handleClick('Cart')}>
          <Link to="/cart">CART</Link>
        </li>
        <li>
          <Link to="/market">MARKET</Link>
        </li>
        <li>
          <p
            onMouseOver={() => {
              console.log('over');
            }}
          >
            {user ? user.username : ' '}
          </p>
        </li>
        <li onClick={() => handleClick('Logout')}>
          <button type="button" onClick={() => handleSignOut()}>
            LOGOUT
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Options;

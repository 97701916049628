import React from 'react';
import { Container } from './styles';

export const Question1 = () => {
  return (
    <Container>
      <span>
        Assuming you already have a Discord account, invite Ilumnia to your
        server{' '}
        <mark>
          (permissions are very important, please do not deselect any of them
          🙃)
        </mark>
        , then type !start, enter a valid nickname, choose your race, and your
        character is ready to play(remember that your profile is loaded for all
        servers, that is, you will not need to start from zero for each server),
        and finally, learn to play with the command !tutorial
      </span>
    </Container>
  );
};
export const Question2 = () => {
  return (
    <Container>
      <span>
        Just mention the bot with <mark>@Ilumnia</mark> and it will reply with
        your server prefix
      </span>
    </Container>
  );
};
export const Question3 = () => {
  return (
    <Container>
      <span>
        Type the command !deleteaccount and then start again with !start,{' '}
        <mark>once you delete your account, there is no way to going back</mark>
        .
      </span>
    </Container>
  );
};
export const Question4 = () => {
  return (
    <Container>
      <span>
        Bugs are reported on the bug-report channel within our official server{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discordapp.com/invite/8UXcdPw"
        >
          click here to enter
        </a>
      </span>
    </Container>
  );
};
export const Question5 = () => {
  return (
    <Container>
      <span>
        You can see all donations on the{' '}
        <a target="_blank" rel="noopener noreferrer" href="/donate">
          donate page
        </a>{' '}
        <mark>(you need to be logged in website to add to the cart)</mark>.
      </span>
    </Container>
  );
};
export const Question6 = () => {
  return (
    <Container>
      <span>
        It is impossible for anyone to pick up any of your items without your
        consent, so Staff is not responsible for any trade and will not
        investigate the theft.
      </span>
    </Container>
  );
};
export const Question7 = () => {
  return (
    <Container>
      <span>
        This is not even a question, and all nerfs and buffs are based on the
        game&rsquo;s continuous progression, you can give feedback on the
        feedback channel inside the official server{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://discordapp.com/invite/8UXcdPw"
        >
          click here to enter
        </a>
        .
      </span>
    </Container>
  );
};
export const Question8 = () => {
  return (
    <Container>
      <span>
        Unfortunately we are not working on it yet, who knows in the future? :)
      </span>
    </Container>
  );
};
export const Question9 = () => {
  return (
    <Container>
      <span>We love it.</span>
      <img
        src="https://ilumnia.s3.amazonaws.com/website/poring.gif"
        alt="Poring icon"
      />
    </Container>
  );
};
export const Question10 = () => {
  return (
    <Container>
      <span>
        lumnia is not affiliated and does not have any rights to any RPG shown
        in the game. All artwork and codes are credited to their respective
        creators. Donations raised are for server payments, maintenance and
        future development. <br />
        <br />
        <strong>Ilumnia is based in the following RPGs:</strong> <br />
        Ragnarok Online <br />
        Tree of Savior <br />
        Black Desert Online <br />
      </span>
    </Container>
  );
};

import styled from 'styled-components';

export const ContainerMenu = styled.div`
  @media (min-width: 1050px) {
    display: none;
  }
  top: 0;
  right: 0;
  height: 100vh;
  width: ${props => (props.clicked ? '300px' : '0px')};
  background-color: #000;
  position: fixed;
  z-index: 99;
  transition: 0.2s ease-in;

  ul {
    margin-top: px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  li {
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    width: 100%;
  }

  button {
    text-align: start;
    background: none;
    font-weight: 400;
    font-size: 1.5em;
    color: #fff;
    border: 0;
    width: 100%;
    &:hover {
      font-weight: 600;
    }
  }

  p {
    display: flex;
    font-size: 1.5em;
    color: #fff;
  }

  a {
    display: flex;
    font-size: 1.5em;
    color: #fff;
    text-decoration: none;
    &:hover {
      font-weight: 600;
    }
  }
`;

export const ContainerHamburger = styled.div`
  background: #131313;
  width: 50px;
  height: 50px;
  right: 25px;
  top: 13px;
  position: fixed;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1050px) {
    display: none;
  }
`;

export const Hamburguer = styled.span`
  position: relative;
  display: block;
  background: #fff;
  width: 25px;
  height: 2px;
  top: 23.5px;
  left: 12.5px;
  transition: 0.2s ease-in-out;

  &::after,
  ::before {
    display: block;
    background: #fff;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &::before {
    top: ${props => (props.clicked ? '0px' : '-10px')};
    transform: ${props => (props.clicked ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: 0.2s ease-in-out;
  }

  &::after {
    bottom: ${props => (props.clicked ? '0px' : '-10px')};
    transform: ${props => (props.clicked ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: 0.2s ease-in-out;
  }

  transform: ${props => (props.clicked ? 'rotate(45deg)' : 'rotate(0deg)')};
`;

import React from 'react';
import {
  Container,
  WhySection,
  Section,
  SectionContainer,
  ProgressSection,
  FeatureSection,
  FaqSection,
  FooterContainer,
  Wrapper,
} from './styles';

import BIG_TRIANGLE from '~/assets/home/BIG_TRIANGLE.svg';
import TINY_TRIANGLE from '~/assets/home/TINY_TRIANGLE.svg';
import SQUARE from '~/assets/home/SQUARE.svg';
import discord_logo_white from '~/assets/home/discord-logo.svg';
import discord_logo_black from '~/assets/home/discord_logo_black.svg';
import background from '~/assets/home/background.svg';

import Footer from '~/components/Footer';
import Top from './components/Top';
import Faq from './components/Faq';
import Features from './components/Features';
import Progress from './components/Progress';
import Why from './components/Why';

export default function Home() {
  return (
    <>
      <Container>
        <Top />
        <WhySection>
          <Section theme="white" data-aos="fade-up" data-aos-once="true">
            <div className="paragraph">About Us</div>
            <div className="line" />
          </Section>

          <SectionContainer
            theme="white"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h1>Why choose Ilumnia</h1>
            <Why />
          </SectionContainer>
        </WhySection>

        <ProgressSection>
          <Section theme="black" data-aos="fade-up" data-aos-once="true">
            <div className="paragraph">Our progress</div>
            <div className="line" />
          </Section>

          <SectionContainer theme="black">
            <img className="big-triangle" src={BIG_TRIANGLE} alt="Triangle" />
            <img className="tiny-triangle" src={TINY_TRIANGLE} alt="Triangle" />
            <img className="square" src={SQUARE} alt="Square" />
            <h1 data-aos="fade-up" data-aos-once="true">
              ILUMNIA
            </h1>
            <Progress />
          </SectionContainer>
        </ProgressSection>
        <FeatureSection>
          <Section theme="white">
            <div className="paragraph">Features</div>
            <div className="line" />
          </Section>

          <SectionContainer theme="white">
            <h1 data-aos="fade-up" data-aos-once="true">
              Our content
            </h1>
            <Features />
          </SectionContainer>
        </FeatureSection>

        <FaqSection>
          <Section theme="black" data-aos="fade-up" data-aos-once="true">
            <div className="paragraph">FAQ</div>
            <div className="line" />
          </Section>

          <SectionContainer theme="black">
            <h1 data-aos="fade-up" data-aos-once="true">
              Frequently Asked questions
            </h1>
            <Faq />
          </SectionContainer>
        </FaqSection>
        <Wrapper background={background}>
          <FooterContainer data-aos="fade-up" data-aos-once="true">
            <h1>Ilumnia</h1>
            <h2>How about you try it out yourself?</h2>
            <p>We want you to experience what a real Discord MMORPG means.</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://discordapp.com/oauth2/authorize?client_id=644244839780712499&scope=bot&permissions=1073752128"
            >
              <img src={discord_logo_white} alt="Discord Logo" />
              Invite to server
            </a>
          </FooterContainer>
          <FooterContainer
            theme="white"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <h1>Ilumnia</h1>
            <h2>Questions? Bug Report? Feedbacks?</h2>
            <p>Let us hear you in our official server</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://discordapp.com/invite/8UXcdPw"
            >
              <img src={discord_logo_black} alt="Discord Logo" />
              Official Server
            </a>
          </FooterContainer>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

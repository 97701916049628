import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'react-loader-spinner';
import { MdClose, MdRemove, MdAdd } from 'react-icons/md';
import { Container, ProductList, Total } from './styles';
import Footer from '../../components/Footer';

import {
  AddToCart,
  RemoveFromCart,
  PaymentRequest,
  DeleteFromCart,
} from '~/store/modules/cart/actions';

import { store } from '~/store';

export default function Cart() {
  const dispatch = useDispatch();
  const { loading, cart } = useSelector(state => state.cart);
  const { id, username } = useSelector(state => state.user.profile);
  const { signed } = store.getState().auth;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let totalValue = 0;
    cart.forEach(i => {
      totalValue += (i.amount / 100) * i.quantity;
    });
    setTotal(totalValue);
  }, [cart]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  function handleRemoveCart(vip) {
    dispatch(RemoveFromCart(vip));
  }

  function handleDeleteCart(vip) {
    dispatch(DeleteFromCart(vip));
  }

  async function handlePurchase() {
    // Envia payment pro checkout
    dispatch(PaymentRequest(cart, id, username));
  }

  function handleAddCart(vip) {
    dispatch(AddToCart(vip));
  }

  return (
    <>
      <Container>
        <ProductList>
          <thead>
            <th />
            <th>PRODUCT</th>
            <th>QUANTITY</th>
            <th>TOTAL PRICE</th>
            <th />
          </thead>
          <tbody>
            {cart && cart.length > 0 ? (
              cart.map(i => (
                <tr key={i.name}>
                  <td>
                    <img src={i.images[0]} alt={i.name} />
                  </td>
                  <td>
                    <strong>{i.name}</strong>
                    <span>{formatter.format(i.amount / 100)}</span>
                  </td>
                  <td>
                    <div>
                      <button
                        type="button"
                        disabled={i.quantity === 1}
                        onClick={() => handleRemoveCart(i)}
                      >
                        <MdRemove
                          size={20}
                          color={i.quantity === 1 ? '#ddd' : '#000'}
                        />
                      </button>
                      <input type="number" readOnly value={i.quantity} />
                      <button type="button" onClick={() => handleAddCart(i)}>
                        <MdAdd size={20} color="#000" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <strong>
                      {formatter.format((i.amount / 100) * i.quantity)}
                    </strong>
                  </td>
                  <td>
                    <button type="button" onClick={() => handleDeleteCart(i)}>
                      <MdClose size={20} color="#000" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <div>
                <strong>Empty cart</strong>
              </div>
            )}
          </tbody>
        </ProductList>
        <footer>
          <button
            type="button"
            disabled={!signed || cart.length === 0}
            onClick={() => handlePurchase(cart)}
          >
            {loading ? (
              <Loader type="TailSpin" height={20} color="#000" />
            ) : (
              'CHECKOUT'
            )}
          </button>
          <Total>
            <span>TOTAL</span>
            <strong>{formatter.format(total)}</strong>
          </Total>
        </footer>
      </Container>
      <Footer />
    </>
  );
}

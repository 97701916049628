import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { updateUser } from './actions';

export function* requestUser() {
  try {
    const response = yield call(api.post, 'user');
    yield put(updateUser(response.data.user));
  } catch (err) {
    console.tron.log(err);
  }
}

export default all([takeLatest('@user/UPDATE_USER_REQUEST', requestUser)]);

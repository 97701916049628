import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 91.8vh;
  font-family: MontSerrat, sans-serif;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  height: 90vh;
  background-color: #131313;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: right bottom;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2em;
  position: relative;

  @media (max-width: 1100px) {
    height: 60vh;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    background-image: none;
  }
`;

export const TopTextContainer = styled.div`
  margin-left: 5em;

  @media (max-width: 1100px) {
    margin-top: 5em;
    margin-left: 2em;
  }

  h1 {
    font-weight: 300;
    color: #fff;
    font-size: 2.5em;
    text-transform: uppercase;
    border-left: 1px solid #5061ca;
    padding-left: 0.4em;
  }

  p {
    color: #fff;
    font-size: 1.6em;
    max-width: 740px;
    margin-top: 1em;
  }

  div {
    margin-top: 4em;
    a {
      white-space: nowrap;
      min-width: 150px;
      font-size: 1.3em;
      padding: 20px;
      color: #fff;
      transition: all 0.2s;
      cursor: pointer;
    }

    .official-server {
      border: 1px solid #fff;
      margin-right: 2em;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }

    .invite-ilumnia {
      border: 1px solid #2b2e34;
      background-color: #2b2e34;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: #000;
      }
    }
  }

  @media (max-width: 880px) {
    max-width: 90%;

    h1 {
      font-size: 2em;
    }

    div {
      display: flex;
      a {
        max-width: 50%;
        margin: 1em;
        text-align: center;
      }
    }
  }

  @media (max-width: 600px) {
    margin-top: 20px;
    height: 60vh;
    div {
      flex-direction: column;
    }
  }
`;

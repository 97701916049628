import React from 'react';

import potion from '~/assets/home/potion.png';
import dragonegg from '~/assets/home/dragon-egg.png';
import boss from '~/assets/home/boss.png';
import kingdom from '~/assets/home/kingdom.png';
import quest from '~/assets/home/quest.png';
import goldbag from '~/assets/home/gold-bag.png';
import skills from '~/assets/home/skills.png';
import armor from '~/assets/home/armor.png';

import { Container, ItemQuality } from './styles';

function Features() {
  return (
    <Container>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={boss} alt="Bosses" />
        <div>
          <h1>Bosses</h1>
          <p>
            Beat powerful monsters with your party or guild to drop items and
            get exp to progress in the game
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={skills} alt="Skills" />
        <div>
          <h1>Skills</h1>
          <p>
            Cast your skills only clicking in reactions, we&rsquo;re tired of
            typing !cast or something like this
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={armor} alt="equipment" />
        <div>
          <h1>Equipment</h1>
          <p>
            Drop equipments depending of their rarity, you can drop
            <ItemQuality quality="normal"> normal</ItemQuality>,
            <ItemQuality quality="magic"> magic</ItemQuality>,
            <ItemQuality quality="rare"> rare</ItemQuality>,
            <ItemQuality quality="epic"> epic</ItemQuality> or even
            <ItemQuality quality="legendary"> legendary</ItemQuality> items
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={potion} alt="lifeskill" />
        <div>
          <h1>Lifeskill</h1>
          <p>
            Craft mysterious potions or powerful weapons being an alchemist or a
            blacksmith
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={dragonegg} alt="pets" />
        <div>
          <h1>Pets</h1>
          <p>
            Collect pets while progressing in the game, pets can help you
            casting skills doing damage or healing you
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={kingdom} alt="kingdom" />
        <div>
          <h1>Kingdom</h1>
          <p>
            Conquer powerful cities with your guild that helps your guild get
            stronger
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={quest} alt="quests" />
        <div>
          <h1>Quests</h1>
          <p>
            Do epic quests while you battle with your friends to help you get
            stronger and rich
          </p>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true">
        <img src={goldbag} alt="economy" />
        <div>
          <h1>Economy</h1>
          <p>
            Meaningful economy that allows you buy or sell items in our market
          </p>
        </div>
      </div>
    </Container>
  );
}

export default Features;

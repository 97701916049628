import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700&display=swap');

  .Toastify__toast--success {
    background: #7289da !important;
 }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }


  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #1a1a1a;
  }

  body {
    -webkit-font-smoothing: antialiased;

  }

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  body, input, button {
    font: 14px MontSerrat, sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer
  }

  @media (max-width: 1900px) {
    body {
      font-size: 16px;
    }
  }

  @media (max-width: 1550px) {
    body {
      font-size: 14px;
    }
  }

  @media (max-width: 700px) {
    body {
      font-size: 12px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #000;
  padding: 10vh;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-around;

  div {
    display: flex;
    flex-direction: column;

    a {
      color: #ddd;
      text-decoration: none;
      text-align: center;
    }

    h1 {
      color: #fff;
      text-align: center;
    }

    h2 {
      color: #fff;
      font-size: 2em;
      text-align: center;
      font-weight: 400;
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    height: 40vh;
    align-items: center;

    img {
      margin: 0;
    }

    div {
      margin: 0;
      margin-bottom: 20px;
    }
  }
`;

export const Rights = styled.div`
  display: flex;
  background-color: #000;
  justify-content: center;
  p {
    padding-left: 5px;
    border-left: 1px solid #9a9a9a;
    font-size: 12px;
    color: #9a9a9a;
  }
`;

import produce from 'immer';

const INITIAL_STATE = {
  equipment: null,
  consumable: null,
  material: null,
  pet: null,
  loading: false,
};

export default function inventory(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@inventory/INVENTORY_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@market/MARKET_REGISTER_SUCCESS': {
        draft[action.payload.typeMarket] = action.payload.newInventory;
        break;
      }
      case '@inventory/INVENTORY_SUCCESS': {
        draft.equipment = action.payload.equipment;
        draft.consumable = action.payload.consumable;
        draft.material = action.payload.material;
        draft.pet = action.payload.pet;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

import React, { useState } from 'react';

import arrow_down from '~/assets/home/arrow-down.svg';
import { Container, Collapse, Collapsible } from './styles';

import * as q from './Questions';

function Faq() {
  const [questions, setQuestions] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const openCollapse = collapseIndex => {
    const newCollapses = questions.map((i, index) => {
      if (index === collapseIndex) return !i;
      return i;
    });
    setQuestions(newCollapses);
  };

  return (
    <Container data-aos="fade-up" data-aos-once="true">
      <Collapsible isActive={questions[0]}>
        <button type="button" onClick={() => openCollapse(0)}>
          <p>Huh, how do I play this game?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(0)} isActive={questions[0]}>
          <q.Question1 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[1]}>
        <button type="button" onClick={() => openCollapse(1)}>
          <p>I forgot my server&rsquo;s prefix, what should I do?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(1)} isActive={questions[1]}>
          <q.Question2 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[2]}>
        <button type="button" onClick={() => openCollapse(2)}>
          <p>Can I reset my character?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(2)} isActive={questions[2]}>
          <q.Question3 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[3]}>
        <button type="button" onClick={() => openCollapse(3)}>
          <p>Where do I report a bug?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(3)} isActive={questions[3]}>
          <q.Question4 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[4]}>
        <button type="button" onClick={() => openCollapse(4)}>
          <p>How do I support Ilumnia?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(4)} isActive={questions[4]}>
          <q.Question5 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[5]}>
        <button type="button" onClick={() => openCollapse(5)}>
          <p>Someone stole my items, help?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(5)} isActive={questions[5]}>
          <q.Question6 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[6]}>
        <button type="button" onClick={() => openCollapse(6)}>
          <p>Nerf mage</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(6)} isActive={questions[6]}>
          <q.Question7 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[7]}>
        <button type="button" onClick={() => openCollapse(7)}>
          <p>
            Hey, I am developer too, can I have access to Ilumnia&rsquo;s API?
          </p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(7)} isActive={questions[7]}>
          <q.Question8 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[8]}>
        <button type="button" onClick={() => openCollapse(8)}>
          <p>Ragnarok Online?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(8)} isActive={questions[8]}>
          <q.Question9 />
        </Collapse>
      </Collapsible>

      <Collapsible isActive={questions[9]}>
        <button type="button" onClick={() => openCollapse(9)}>
          <p>Is Ilumnia affiliated with any RPG showed on the game?</p>
          <img src={arrow_down} className="down" alt="arrow down" />
        </button>
        <Collapse onClick={() => openCollapse(9)} isActive={questions[9]}>
          <q.Question10 />
        </Collapse>
      </Collapsible>
    </Container>
  );
}

export default Faq;

import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { signFailure } from '~/store/modules/auth/actions';
import { menuClick } from '~/store/modules/menu/actions';

function OptionsAuth() {
  const loading = useSelector(state => state.auth.loading);
  const dispatch = useDispatch();

  function handleClick(location) {
    dispatch(menuClick(location));
  }
  useState(() => {
    dispatch(signFailure());
  }, []);

  return (
    <nav>
      <ul>
        <li onClick={() => handleClick('Donate')}>
          <a href="/donate">DONATE</a>
        </li>
        <li onClick={() => handleClick('Vote')}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://top.gg/bot/644244839780712499/vote"
          >
            VOTE
          </a>
        </li>
        <li onClick={() => handleClick('Invite')}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://discordapp.com/oauth2/authorize?client_id=644244839780712499&scope=bot&permissions=1073752128"
          >
            INVITE
          </a>
        </li>
        <li onClick={() => handleClick('Login')}>
          {loading ? (
            <Loader type="TailSpin" height={20} color="#fff" />
          ) : (
            <a href="https://discordapp.com/api/oauth2/authorize?client_id=644244839780712499&redirect_uri=https%3A%2F%2Filumnia.app%2Flogin&response_type=code&scope=identify">
              LOGIN
            </a>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default OptionsAuth;

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdRefresh, MdArrowBack } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';
import {
  Container,
  MarketContainer,
  FilterContainer,
  ItemsContainer,
} from './styles';

import MarketList from '~/components/MarketList';
import InventoryList from '~/components/InventoryList';

import {
  marketRequest,
  orderMarket,
  marketRequestName,
} from '~/store/modules/market/actions';

export default function Market() {
  const [component, setComponent] = useState('market');

  function renderComponent() {
    switch (component) {
      case 'inventory': {
        return <InventoryList />;
      }
      default:
        return <MarketList />;
    }
  }

  return (
    <Container>
      <ItemsContainer>
        <h2>Balance:</h2>
        <p>1.332.221.443</p>
        <hr />
        <ul>
          <li>
            <button type="button">Main Weapon</button>
          </li>
          <li>
            <button type="button">Sub Weapon</button>
          </li>
          <li>
            <button type="button">Armor</button>
          </li>
          <li>
            <button type="button">Accessory</button>
          </li>
          <li>
            <button type="button">Consumables</button>
          </li>
        </ul>
      </ItemsContainer>
      <MarketContainer>
        <div className="market-container">
          <button
            type="button"
            onClick={() => {
              if (component !== 'inventory') setComponent('inventory');
            }}
          >
            Register
          </button>
          <button type="button">My Listings</button>
          <p>Icon</p>
        </div>
        <hr />
        {renderComponent()}
      </MarketContainer>
      <FilterContainer>
        <p>oi</p>
      </FilterContainer>
    </Container>
  );
}

import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';
import { PaymentSuccess } from './actions';

export function* PaymentRequestAPI({ payload }) {
  const { cart, user_id, username } = payload;
  const response = yield call(api.post, '/payment', {
    cart,
    user_id,
    username,
  });
  const publicKey = yield call(api.post, '/stripe/public_key', {
    pass: '7L^cXKQicjl&',
  });
  const { key } = publicKey.data;

  const stripe = window.Stripe(key);

  const { id } = response.data;
  stripe
    .redirectToCheckout({
      sessionId: id,
    })
    .then(function(result) {
      toast.error(result.error.message);
    });
  yield put(PaymentSuccess());
}

export default all([takeLatest('@cart/PAYMENT_REQUEST', PaymentRequestAPI)]);

import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  right: 0;
  left: 0;
  margin: 0 auto;
  display: grid;
  grid-gap: 1.5em;
`;

export const Collapsible = styled.div`
  button {
    width: 600px;
    height: 70px;
    background-color: ${lighten(0.05, '#161616')};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #fff;
    border: 0;
    transition: all 0.2s;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      opacity: 0.7;
    }

    p {
      flex: 1;
      font-size: 1.3em;
      padding-left: 10px;
      margin: 0;
      text-align: center;
    }

    .down {
      height: 15px;
      width: 25px;
      margin: 0 2em;
      transform: ${props =>
        props.isActive ? 'rotateZ(0deg)' : 'rotateZ(-90deg)'};
      transition: all 0.3s ease-out;
    }

    @media (max-width: 880px) {
      width: 100%;
    }
  }
`;

export const Collapse = styled.div`
  max-height: ${props => (props.isActive ? '400px' : 0)};
  background-color: #303030;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
  }
`;

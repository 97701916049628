export function inventoryRequest() {
  return {
    type: '@inventory/INVENTORY_REQUEST',
  };
}

export function inventoryFailure() {
  return {
    type: '@inventory/INVENTORY_FAILURE',
  };
}

export function inventorySuccess(equipment, consumable, material, pet) {
  return {
    type: '@inventory/INVENTORY_SUCCESS',
    payload: { equipment, consumable, material, pet },
  };
}

import produce from 'immer';

const INITIAL_STATE = {
  market: null,
  listings: null,
  loading: false,
  loadingListing: false,
};

export default function market(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@market/MARKET_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@market/MARKET_SUCCESS': {
        draft.market = action.payload.market;
        draft.loading = false;
        break;
      }

      case '@market/MARKET_BUY_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@market/MARKET_LISTINGS_REQUEST': {
        draft.loadingListing = true;
        break;
      }

      case '@market/MARKET_LISTINGS_SUCCESS': {
        draft.listings = action.payload.listings;
        draft.loadingListing = false;
        break;
      }

      case '@market/MARKET_LISTINGS_FAILURE': {
        draft.loadingListing = false;
        break;
      }

      case '@market/MARKET_ORDER': {
        draft.market = action.payload.market;
        break;
      }

      case '@market/MARKET_BUY_SUCCESS': {
        draft.loading = false;
        draft.market = action.payload.market;
        break;
      }

      case '@market/MARKET_BUY_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.market = null;
        break;
      }
      default:
    }
  });
}

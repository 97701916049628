export function updateUser(user) {
  return {
    type: '@user/UPDATE_USER',
    payload: { user },
  };
}

export function updateUserRequest() {
  return {
    type: '@user/UPDATE_USER_REQUEST',
    payload: {},
  };
}

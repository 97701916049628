import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import api from '~/services/api';

import { Container, Content, Products } from './styles';
import history from '~/services/history';

export default function PaymentSuccess() {
  const [session, setSession] = useState({});

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    if (!queryString.parse(window.location.search)) return history.push('/');
    async function fetchData() {
      const getSession = await api.post(
        '/stripe/session',
        queryString.parse(window.location.search)
      );
      if (!getSession.data) return history.push('/');
      return setSession(getSession.data.display_items);
    }
    fetchData();
  }, []);

  return (
    <Container>
      <Content>
        <h1>Thank you for your donation</h1>
        <h2>You bought</h2>
        {session && session[0]
          ? session.map(i => (
              <Products key={i.custom.name}>
                <img src={i.custom.images[0]} alt={i.custom.description} />
                <div>
                  <p>
                    {i.custom.name} {i.quantity > 1 ? `x${i.quantity}` : null}
                  </p>
                  <p>{formatter.format((i.amount / 100) * i.quantity)}</p>
                  <span>{i.custom.description}</span>
                </div>
              </Products>
            ))
          : null}
      </Content>
    </Container>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { ContainerHamburger, Hamburguer, ContainerMenu } from './styles';

import Options from '../Header/Options';
import OptionsAuth from '../HeaderAuth/Options';
import { menuClick } from '~/store/modules/menu/actions';

function Menu({ signed }) {
  const dispatch = useDispatch();
  const { clicked } = useSelector(state => state.menu);

  function handleClick() {
    dispatch(menuClick(null));
  }

  return (
    <>
      <ContainerHamburger onClick={() => handleClick()}>
        <Hamburguer clicked={clicked} />
      </ContainerHamburger>
      <ContainerMenu clicked={clicked}>
        {signed ? (
          <Options onClick={() => handleClick()} />
        ) : (
          <OptionsAuth onClick={() => handleClick()} />
        )}
      </ContainerMenu>
    </>
  );
}

Menu.propTypes = {
  signed: PropTypes.bool,
};

Menu.defaultProps = {
  signed: false,
};

export default Menu;

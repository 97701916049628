import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { MarketTable, Scroll, TableItem } from './styles';

import {
  marketRequest,
  orderMarket,
  marketRequestName,
} from '~/store/modules/market/actions';

export default function MarketList() {
  const dispatch = useDispatch();
  const marketItens = useSelector(state => state.market.market);

  useEffect(() => {
    dispatch(marketRequest('equipment'));
  }, [dispatch]);

  console.tron.log(marketItens);

  return (
    <Scroll>
      <MarketTable>
        <thead>
          <th />
          <th>Item</th>
          <th>Price</th>
          <th>In Stock</th>
          <th />
        </thead>
        <tbody>
          {marketItens
            ? marketItens.map(i => (
                <TableItem quality={i.equipment.quality_id} key={i.id}>
                  <td>
                    <img src={i.equipment.image} alt="" />
                  </td>
                  <td className="item_name">
                    ({i.equipment.minimum_level}) {i.equipment.name}
                  </td>
                  <td>{i.silver_price}</td>
                  <td>{i.quantity}</td>
                  <td>
                    <button type="button">Details</button>
                  </td>
                </TableItem>
              ))
            : null}
        </tbody>
      </MarketTable>
    </Scroll>
  );
}

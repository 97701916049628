import produce from 'immer';

const INITIAL_STATE = {
  cart: [],
  loading: false,
};

export default function cart(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@cart/ADD_ITEM': {
        const itemIndex = draft.cart.findIndex(
          p => p.name === action.payload.item.name
        );

        if (itemIndex >= 0) {
          draft.cart[itemIndex].quantity += 1;
        } else {
          draft.cart.push(action.payload.item);
        }
        break;
      }

      case '@cart/REMOVE_ITEM': {
        const itemIndex = draft.cart.findIndex(
          p => p.name === action.payload.item.name
        );

        if (itemIndex >= 0) {
          draft.cart[itemIndex].quantity -= 1;
        }
        break;
      }

      case '@cart/DELETE_ITEM': {
        const itemIndex = draft.cart.findIndex(
          p => p.name === action.payload.item.name
        );

        if (itemIndex >= 0) {
          draft.cart.splice(itemIndex, 1);
        }
        break;
      }

      case '@cart/PAYMENT_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@cart/PAYMENT_SUCCESS': {
        draft.loading = false;
        draft.cart = [];
        break;
      }
      default:
    }
  });
}

import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Home from '../pages/Home';
import Login from '../pages/Login';
import Donate from '../pages/Donate';
import Market from '../pages/Market';
import Contact from '../pages/Contact';
import PaymentSuccess from '../pages/PaymentSuccess';
import Cart from '../pages/Cart';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/donate" component={Donate} />
      <Route path="/market" component={Market} isPrivate />
      <Route path="/contact" component={Contact} isPrivate />
      <Route path="/success" component={PaymentSuccess} isPrivate />
      <Route path="/cart" component={Cart} isPrivate />

      <Route
        path="/invite"
        component={() => {
          window.location.href =
            'https://discord.com/oauth2/authorize?client_id=644244839780712499&scope=bot&permissions=1073752128';
          return null;
        }}
      />
    </Switch>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import Menu from '../Menu';
import Options from './Options';

export default function HeaderHome() {
  return (
    <>
      <Container>
        <Link to="/">
          <h1>ILUMNIA</h1>
        </Link>
        <Options />
      </Container>
      <Menu signed={false} />
    </>
  );
}

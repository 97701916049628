import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { signInSuccess, signFailure } from './actions';
import history from '~/services/history';

export function* signIn({ payload }) {
  const { code } = payload;

  const headers = new Headers();

  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  headers.append('Access-Control-Allow-Origin', 'https://ilumnia.app');
  headers.append('Access-Control-Allow-Credentials', 'true');

  headers.append('GET', 'POST', 'OPTIONS');
  const response = yield call(api.post, 'callback', {
    code,
    headers,
  });

  const { discord, user, stats, itemStatus } = response.data;

  if (!user) {
    toast.error('User not found, sign up in Ilumnia');
    yield put(signFailure());
    return;
  }

  yield put(signInSuccess(discord, user, stats, itemStatus));
  api.defaults.headers.Authorization = `Bearer ${discord.access_token}`;
  api.defaults.headers.ilumnia_user_id = user.id;

  history.push('/');
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;

  if (payload.user.profile) {
    const { id } = payload.user.profile;
    api.defaults.headers.ilumnia_user_id = id;
  }

  api.defaults.headers.Authorization = `Bearer ${token}`;
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);

import React from 'react';

// import { Container } from './styles';

function Why() {
  return (
    <>
      <p>
        Ilumnia is the first true MMORPG discord bot, where you can choose a
        class between 6 starting classes, with class evolution tree, variety of
        skills, roles like tanker classes, healer classes and damage classes,
        where you can gather your friends and battle monsters together and of
        course, no turn based
      </p>
      <p>
        If you feel you getting too strong, why not making your own guild and
        start killing raid bosses to get the best weapons in the game with
        unique effects.
      </p>
      <p>
        Our market is one of the most important points in the game since you can
        trade everything like equipments, enchant materials, elixirs, potions,
        etc.
      </p>
    </>
  );
}

export default Why;
